<template>
  <b-card>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Currency Name"
            label-for="c-name"
            label-cols-md="4"
          >
            <b-form-input
              id="c-name"
              placeholder="Currency Name"
              v-model="form.c_name"
              required
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Currency Code"
            label-for="c-code"
            label-cols-md="4"
            required
          >
            <b-form-input
              id="c-code"
              placeholder="Currency Code"
              v-model="form.c_code"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Currency Symbol"
            label-for="c-symbol"
            label-cols-md="4"
            required
          >
            <b-form-input
              id="c-symbol"
              placeholder="Currency Symbol"
              v-model="form.c_symbol"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12">
          <b-form-group
            label="Currency Symbol"
            label-for="c-Territory"
            label-cols-md="4"
          >
            <b-form-input
              id="c-territory"
              placeholder="Currency Territory"
              v-model="form.c_territory"
            />
          </b-form-group>
        </b-col> -->

        <b-col cols="12">
          <b-form-group
            label="Currency Rupees Conversion"
            label-for="c-rupee-conversion"
            label-cols-md="4"
            required
          >
            <b-form-input
              id="c-rupee-conversion"
              type="number"
              placeholder="Currency Rupees Conversion"
              v-model="form.c_rupee_conversion"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="reset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MasterServices from "@/apiServices/MasterServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        c_id: null,
        c_name: null,
        c_code: null,
        c_symbol: null,
        c_territory: null,
        c_rupee_conversion: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        const response = await MasterServices.saveCurrency(this.form);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Currency Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Currrency Not Saved",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        console.log("Error in saving currency ", err);
      }
    },
    reset() {
      this.form = {
        c_name: null,
        c_name: null,
        c_code: null,
        c_symbol: null,
        c_territory: null,
        c_rupee_conversion: null,
      };
    },
    async getDetails() {
      if (!this.form.c_id) {
        return;
      }
      try {
        const response = await MasterServices.getSingleCurrency({
          c_id: this.form.c_id,
        });
        if (response.data.status) {
          this.form = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting currency details ", err);
      }
    },
  },
  beforeMount() {
    const { c_id } = this.$route.params;
    if (c_id && !isNaN(c_id)) {
      this.form.c_id = c_id;
      this.getDetails();
    }
  },
};
</script>

<style>
</style>